import { Uuid } from "../api/types";
import { compareAlphaNum } from "./compare";

export type RequireFields<T extends Record<keyof any, unknown>> = T & { [key: keyof any]: any }

export function sleep(ms: number = 0) {
	return new Promise((r) => setTimeout(r, ms));
}

// eslint-disable-next-line no-unused-vars
export function assertUnreachable(_: never): never {
	throw new Error("Didn't expect to get here");
}

export function inlineThrow(message: string): never {
	throw new Error(message);
}

export function invokeAndThrow(message: string, callback: () => void): never {
	callback();
	throw new Error(message);
}

export function isKeyOf<O extends object>(obj: O) {
	return <K extends keyof any>(key: K): key is K & keyof O => {
		return key in obj;
	};
}

export function getValue<O extends object, K extends keyof O>(obj: O, key?: K | any) {
	if (key === undefined) {
		return undefined;
	}
	if (!(key in obj)) {
		return undefined;
	}
	return key === undefined ? undefined : obj[key as keyof O];
}

export function findKeyOf<O extends object>(obj: O, arr?: Array<keyof any>) {
	return arr === undefined ? undefined : arr.find(isKeyOf(obj));
}

export function mapOr<V, T>(value: V, opt?: T): T extends undefined ? V : T {
	return (opt === undefined ? value : opt) as T extends undefined ? V : T;
}

export function keys<K extends keyof any>(obj: Map<K, unknown>): Array<K>;
export function keys<K extends keyof any>(obj: Set<K>): Array<K>;
export function keys<O extends object>(obj: O): Array<keyof O>;
export function keys(obj: any): any {
	if (obj instanceof Map || obj instanceof Set) {
		return Array.from(obj.keys());
	}
	return Object.keys(obj);
}

export function values<V>(obj: Map<unknown, V>): Array<V>;
export function values<O extends object>(obj: O): Array<typeof obj[keyof typeof obj]>;
export function values<O extends object>(obj: O): Array<unknown> {
	if (obj instanceof Map) {
		return Array.from(obj.values());
	}
	return Object.values(obj);
}

export function entries<K, V>(obj: Map<K, V>): [K, V][]
export function entries<O extends object>(obj: O): Array<[keyof typeof obj, typeof obj[keyof typeof obj]]>
export function entries(obj: any): [unknown, unknown][] {
	if (obj instanceof Map) {
		return Array.from(obj.entries());
	}
	return Object.entries(obj);

}

export function filter<T>(arr: T[], predicate: (value: T, index: number) => boolean): T[] {
	return arr.filter(predicate);
}

export function filterMap<K, V>(map: Map<K, V>, predicate: ([k, v]: [K, V]) => boolean): Map<K, V> {
	return new Map(entries(map).filter(predicate));
}

export function remap<K, V, T, S>(map: Map<K, V>, predicate: ([k, v]: [K, V]) => [T, S]): Map<T, S> {
	return new Map(entries(map).map(predicate));
}

export function emptyMap(): Map<any, any> {
	return new Map();
}

export function sortByAlphaNum<T extends Record<K, string>, K extends string>(field: K, arr: T[]): T[] {
	return [...arr].sort(compareAlphaNum(field));
}

export function lowercase<T extends string>(str: T): Lowercase<T> {
	return str.toLowerCase() as Lowercase<T>;
}

export function uppercase<T extends string>(str: T): Uppercase<T> {
	return str.toUpperCase() as Uppercase<T>;
}

export function uuid(): Uuid {
	return crypto.randomUUID() as Uuid;
}

type Contains<Obj extends Record<string, unknown>, Key extends string, Value> =
	Key extends keyof Obj
	? Obj[Key] extends Value
	? true
	: never
	: never;

type MapIdKey<Key extends string, Obj extends Record<string, unknown>> =
	Contains<Obj, Key, Uuid> extends true
	? Record<Uuid, Omit<Obj, Key>>
	: never;

// takes a key name that exists within the items of a provided array, and return an object, with the key's value as the key, and the rest of the item as the value.
export function mapIdKey<Key extends string, Obj extends Record<string, unknown>>(name: Key, arr: Array<Obj>): MapIdKey<Key, Obj> {
	const result: Record<Uuid, any> = {};

	for (const item of arr) {
		const key = item[name] as Uuid;
		delete item[name];
		result[key] = { ...item };
	}

	return result;
}

type IsEmpty<T> = T extends null
	? true
	: T extends undefined
	? true
	: T extends ''
	? true
	: false;

export function isEmpty<T>(value: T): IsEmpty<T> {
	return (value === null || value === undefined || value === '') as IsEmpty<T>;
}

type IsNested<T> = T extends Record<keyof any, unknown>
	? true
	: false;

export function isNested<T>(value: T): IsNested<T> {
	return (typeof value === 'object' && !Array.isArray(value) && value !== null) as IsNested<T>;
}

export function isObjectEmpty<T extends object>(value: T): boolean {
	return keys(value).length === 0;
}

/**
 * Recursively "unproxies" an object or map by performing structured cloning.
 * This function works with nested structures and ensures that the final output is free of proxies.
 *
 * @param input - The input object, map, or any nested structure to be unproxied.
 * @returns A deeply cloned and unproxied version of the input.
 */
export function unproxy<T>(value: T): unknown {

	if (value === null || typeof value !== "object") {
		return value; // Primitive types are returned as is
	}

	if (Array.isArray(value)) {
		return value.map(unproxy); // Recursively clone arrays
	}

	if (value instanceof Map) {
		return new Map(Array.from(value.entries()).map(([key, val]) => [unproxy(key), unproxy(val)]));
	}

	if (value instanceof Set) {
		return new Set(Array.from(value).map(unproxy)); // Clone Sets recursively
	}

	if (value instanceof Date) {
		return new Date(value.getTime()); // Clone Dates
	}

	if (value instanceof RegExp) {
		return new RegExp(value.source, value.flags); // Clone Regular Expressions
	}

	// Clone plain objects
	return Object.keys(value).reduce((acc, key) => {
		(acc as any)[key] = unproxy((value as any)[key]);
		return acc;
	}, {} as Record<string, any>);
}
