<template lang="pug">
.dropdown-container(:class="modifierClasses")
	VTooltip(:text="tooltip")
		label(v-if="header") {{ header }}
		.dropdown(
			:class="{ focus: dropdownActive, disabled, 'no-scroll': noScroll }"
			v-click-outside="hideDropdown"
		)
			.label(
				ref="label"
				@click="toggleDropdown"
			)
				VIcon(
					v-if="icon"
					:name="icon"
				)
				.name(:title="label")
					span {{ label }}
				VIcon.reset(
					v-if="resetable"
					name="cross"
					@click="reset"
				)
				VIcon.disable-icon(
					v-else-if="disabled"
					name="lock"
				)
				VIcon.arrow(
					v-else
					name="arrow/down.simple"
				)
			.content(
				v-if="dropdownActive"
				ref="content"
			)
				slot
				SearchBox(
					v-if="showSearchbox"
					:modelValue="searchInput"
					focusOnLoad
					@update:modelValue="searchInput = $event"
				)
				ul.list(v-if="listProvided")
					DropdownItem.select-all(
						v-if="selectAll"
						:item="selectAllItem"
						:displayCheck="false"
						@toggleCheck="toggleCheckSelectAll"
					)
					template(
						v-for="item in filteredItems"
						:key="item.id"
					)
						DropdownItem(
							:item="item"
							:multiSelect="multiSelect"
							:displayCheck="'isChecked' in item"
							:disabledReason="itemDisabledReason"
							:truncateName="truncateNames"
							indicateNonOperational
							@toggleCheck="toggleCheck"
							@reset="reset"
						)
					li.no-matches(v-if="emptyListMessage && !list.length") {{ emptyListMessage }}
					li.no-matches(v-else-if="noSearchResults") No matches
				.button-container(v-if="cancelButton || confirmButton")
					VButton.invert.secondary(
						v-if="cancelButton"
						@click="$emit('cancel')"
					) {{ cancelButton }}
					VButton(
						v-if="confirmButton"
						:disabled="confirmButtonDisabled"
						@click="$emit('confirm')"
					) {{ confirmButton }}

			slot(name="callout")
</template>

<script lang="ts">
import _ from 'lodash';
import validate, { joi } from '@pv';

import SearchBox from '@/components/base/SearchBox.vue';
import DropdownItem from './DropdownItem.vue';
import { defineComponent, PropType } from 'vue';

export type ListItem<T = undefined> = {
	name: string;
	lifecycleState?: string | undefined;
	id: string, isChecked: boolean | undefined;
	meta: T;
}
export type DropdownUpdate<T = undefined> = {
	item: ListItem<T>,
	isChecked: boolean
}

export default defineComponent({
	name: 'VDropdown',
	components: {
		DropdownItem,
		SearchBox
	},
	props: {
		label: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: false,
			default: ''
		},
		list: {
			type: Array as PropType<Array<ListItem>>,
			required: false,
			validator: validate(joi.array().items(joi.object({
				name: joi.string(),
				// @ts-expect-error This object is not well typed
				lifecycleState: joi.lifecycleState(),
				id: joi.string() // Not necessarily a guid
			}))),
			default: null
		},
		isActive: {
			type: Boolean,
			required: false,
			default: false
		},
		resetable: {
			type: Boolean,
			required: false,
			default: false
		},
		multiSelect: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		searchable: {
			type: Boolean,
			required: false,
			default: false
		},
		lengthUntilSearchable: {
			type: Number,
			required: false,
			default: 4
		},
		header: {
			type: String,
			required: false,
			default: ''
		},
		confirmButton: {
			type: String,
			required: false,
			default: null
		},
		confirmButtonDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		cancelButton: {
			type: String,
			required: false,
			default: null
		},
		emptyListMessage: {
			type: String,
			required: false,
			default: null
		},
		tooltip: {
			type: String,
			required: false,
			default: null
		},
		itemDisabledReason: {
			type: String,
			required: false,
			default: null
		},
		noScroll: {
			type: Boolean,
			required: false,
			default: false
		},
		truncateNames: {
			type: Boolean,
			required: false,
			default: false
		},
		selectAll: {
			type: Boolean,
			requried: false,
			default: false
		}
	},
	emits: ['toggle', 'toggleCheck', 'check', 'reset', 'confirm', 'cancel', 'hide', 'toggleSelectAll'],
	data() {
		return {
			dropdownActive: false,
			searchInput: '',
			position: null as null | 'above' | 'below',
			selectAllItem: { name: 'SELECT ALL', isChecked: false }
		};
	},
	computed: {
		items() {
			return this.list ?
				this.list.map(item => ({
					...item
				}))
				: [];
		},
		showSearchbox() {
			return this.searchable && this.list.length > this.lengthUntilSearchable;
		},
		listProvided() {
			return !!this.$props.list;
		},
		filteredItems() {
			return this.searchInput
				? this.$search.matches(this.sortedItems, this.searchInput)
				: this.sortedItems;
		},
		sortedItems() {
			return _.chain(this.items)
				// @ts-expect-error TODO Type Lodash mixins
				.sortByAlphaNum()
				.sortByFavorites()
				.sortByDedicated()
				.sortByDeprecated()
				.value();
		},
		noSearchResults() {
			return !this.filteredItems.length;
		},
		modifierClasses() {
			return {
				'content-hidden': this.dropdownActive && this.position === null,
				'content-above': this.dropdownActive && this.position === 'above',
				'content-below': this.dropdownActive && this.position === 'below'
			};
		}
	},
	watch: {
		isActive() {
			this.dropdownActive = this.isActive;
		},
		dropdownActive() {
			if (this.dropdownActive) {
				this.$nextTick(this.updatePosition);
			} else {
				this.position = null;
				this.searchInput = '';
			}
		},
		label() {
			if (!this.label) {
				this.items.forEach(item => item.isChecked = false);
			}
		},
		items: {
			handler() {
				if (this.list && this.selectAll) {
					if (this.items.some(item => item.isChecked)) {
						this.selectAllItem.isChecked = true;
					}
					if (this.items.every(item => item.isChecked === false)) {
						this.selectAllItem.isChecked = false;
					}
				}
			}
		},
		selectAllItem: {
			immediate: true,
			deep: true,
			handler() {
				this.selectAllItem.isChecked ? this.selectAllItem.name = 'DESELECT ALL' : this.selectAllItem.name = 'SELECT ALL';
			}
		}
	},
	methods: {
		toggleDropdown() {
			this.dropdownActive = !this.dropdownActive;
			this.$emit('toggle', this.dropdownActive);
		},
		hideDropdown() {
			if (this.dropdownActive) {
				this.dropdownActive = false;
				this.$emit('hide');
			}
		},
		toggleCheck({ item, isChecked }: DropdownUpdate) {

			if (this.multiSelect) {
				this.$emit('toggleCheck', { item, isChecked });
				return;
			}

			this.$emit('check', item);

			// Only hide dropdown automatically if single-select AND no buttons exist
			if (!this.confirmButton && !this.cancelButton) {
				this.hideDropdown();
			}
		},
		toggleCheckSelectAll() {
			this.selectAllItem.isChecked = !this.selectAllItem.isChecked;
			this.$emit('toggleSelectAll', this.selectAllItem.isChecked);
		},
		reset(event: Event) {
			if (event) {
				event.stopPropagation();
			}
			this.$emit('reset');
		},
		updatePosition() {
			if (!this.dropdownActive) {
				return;
			}
			if (!this.$refs.content) {
				return;
			}

			const labelRect = (this.$refs.label as Element).getBoundingClientRect();
			const labelY = labelRect.y + window.scrollY;
			const labelBottomY = labelY + labelRect.height;

			const {
				height: dropdownHeight
			} = (this.$refs.content as Element).getBoundingClientRect();
			const dropdownBottomY = labelBottomY + dropdownHeight;

			const maxY = Math.max(
				document.body.scrollHeight,
				window.innerHeight
			);


			if (dropdownBottomY >= maxY) {
				this.position = 'above';
			} else {
				this.position = 'below';
			}
		}
	}
});
</script>

<style lang="scss" scoped>
.dropdown-container {
	margin: rem-calc(0 0 10);
	position: relative;

	.list-item & {
		margin-bottom: 0;
	}

	&.push-right {
		margin-left: auto;
	}
}

.select-all {
	border-top: 2px solid hsl(var(--white20));
	border-bottom: 2px solid hsl(var(--white20));
}

.dropdown {
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	color: var(--body-color);
	font-family: inherit;
	position: relative;
	background-color: var(--body-background);
	user-select: none;
	font-weight: var(--font-normal);
	font-size: var(--font-body-size);
	width: 100%;

	.filtered &,
	.filtered &.focus {
		border-color: hsl(var(--white) / 10%);
		background-color: hsl(var(--white15));

		&:hover {
			border-color: hsl(var(--white40));
		}

		.label #{v-deep('.icon')} .fill-primary {
			fill: hsl(var(--white));
			opacity: 0.65;
		}

		.label #{v-deep('.icon')} .stroke-primary {
			stroke: hsl(var(--white));
			opacity: 0.65;
		}
	}

	.filtered &.focus,
	.filtered &.focus .content {
		border-color: hsl(var(--white40));
	}

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.focus {
		border-color: hsl(var(--white40));
		background-color: hsl(var(--dark-purple110));

		.content-above & {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}

		.content-below & {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		.content {
			border-color: hsl(var(--white40));
		}

		.label {
			border-bottom-color: hsl(var(--white15));
		}
	}

	&.disabled {
		border-color: hsl(var(--white10));
		color: hsl(var(--white40));
		cursor: not-allowed;

		.label {
			pointer-events: none;
		}
	}
}

.action-dropdown {
	min-width: rem-calc(200);
	margin-bottom: 0;

	&.small {
		min-width: rem-calc(160);
	}

	&.large {
		min-width: rem-calc(235);
	}

	#{v-deep('.content')} {
		padding-top: 0;
		padding-bottom: 0;
	}

	#{v-deep('.tooltip')} {
		width: 100%;
	}

	.list {
		margin: 0;
	}

	#{v-deep('.list-item')} {
		display: flex;
		align-items: center;
		white-space: nowrap;
		padding: rem-calc(15 0);
		cursor: pointer;
		text-overflow: ellipsis;
		overflow: hidden;

		&.disabled {
			cursor: not-allowed;
			color: hsl(var(--white20));

			&:hover {
				color: hsl(var(--white20));
			}
		}

		a {
			display: block;
			margin: rem-calc(-15 0);
			padding: rem-calc(15 0);
			color: inherit;
			text-decoration: none;
		}

		+ .list-item {
			border-top: 1px solid hsl(var(--white15));
		}

		&:hover {
			color: hsl(var(--white70));
		}
	}

	.list-item-expanded {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		+ .list-item {
			border-top: 1px solid hsl(var(--white15));
		}
	}

	&.content-above {
		#{v-deep('.list-item:last-child')} {
			border-bottom: 1px solid hsl(var(--white15));
		}
	}
}

.label {
	display: flex;
	align-items: center;
	margin: rem-calc(0 10);
	padding-top: rem-calc(1);
	line-height: 1.5;
	font-size: 1rem;
	height: calc(var(--input-height) - #{rem-calc(2)});
	cursor: pointer;
	border-bottom: 1px solid transparent;

	.icon {
		margin-right: rem-calc(10);
		position: relative;

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}

		&.arrow,
		&.disable-icon,
		&.reset {
			margin: 0 0 0 auto;
		}

		&.reset {
			left: rem-calc(4);
			width: rem-calc(24);
			height: rem-calc(24);

			&#{v-deep('.stroke-primary')} {
				stroke: hsl(var(--orange));
			}
		}
	}
}

.name {
	min-width: 0;
	margin-right: rem-calc(10);

	span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.content {
	position: absolute;
	right: rem-calc(-1);
	left: rem-calc(-1);
	padding: rem-calc(10);
	border-style: solid;
	border-width: 0 1px 1px;
	border-color: hsl(var(--white20));
	background-color: hsl(var(--dark-purple110));
	z-index: 4;

	.content-hidden & {
		visibility: hidden;
		position: fixed;
		top: 0;
	}

	.content-above & {
		bottom: 100%;
		border-top-width: 1px;
		border-bottom-width: 0;
		border-top-right-radius: var(--global-radius);
		border-top-left-radius: var(--global-radius);
	}

	.content-below & {
		top: 100%;
		border-top-width: 0;
		border-bottom-width: 1px;
		border-bottom-right-radius: var(--global-radius);
		border-bottom-left-radius: var(--global-radius);
	}

	#{v-deep('> *:last-child:not(.list)')} {
		margin-bottom: 0;
	}

	#{v-deep('span.optional')} {
		display: none;
	}
}

.list {
	position: relative;
	margin: rem-calc(-10);
	padding: rem-calc(0 10);
	list-style-type: none;
	max-height: calc(var(--list-item-height) * 5);
	overflow: auto;

	@include scrollbars(0.5rem, hsl(var(--white20)));

	.no-scroll & {
		max-height: none;
		overflow: visible;
	}

	.search-section + & {
		margin-top: rem-calc(0);
	}

	.no-matches {
		display: flex;
		align-items: center;
		min-height: var(--list-item-height);
		font-weight: var(--font-bold);
		line-height: 1.2;
		padding: rem-calc(10 0);
	}
}

.search-section {
	padding-bottom: rem-calc(10);
	border-bottom: 1px solid hsl(var(--white20));

	#{v-deep('.search-box')} {
		margin-bottom: 0;
	}
}

.button-container {
	display: flex;
	padding-top: rem-calc(10);

	.button {
		margin: 0 auto;
	}
}
</style>
