<template lang="pug">
.navbar(:class="{'show-menu': showMenu}")
	.logo
		router-link(to="/")
			img(src="@/assets/images/ksat_logo.svg")
			span.text KOGNI
	.migrated-info(v-if="showMigrationInfo")
		SimplePill(
			status="warning"
			icon="danger.circle.filled"
			:tooltip="migrationInfoText"
			tooltipPosition="bottom"
		) Migration info

	button#toggle-nav(@click="toggleMenu")
		| Menu
		VIcon(:name="menuIcon")
	nav
		template(
			v-for="item in displayedNavItems"
			:key="item.title"
		)
			NavbarItem(
				:item="item"
				:hasNotification="item.title === 'Issues' ? showIssueNotification : false"
				@click="showMenu = false"
				v-if="!item.disabled"
			)
		NavbarDropdown(
			v-if="!showMenu"
			:activeUrls="moreActiveUrls"
		)
			template(
				v-for="item in moreNavItems"
				:key="item.title"
			)
				NavbarItem.dropdown(
					:item="item"
					@click="showMenu = false"
					v-if="!item.disabled"
				)
	#utc-time(@click="copyISO")
		.date
			VIcon(name="calendar")
			| {{ now.toFormat('ccc dd-LL') }}
		.time
			VIcon(name="time")
			| UTC {{ now.toFormat('HH:mm:ss') }}
		.copy
			VIcon(name="copy")
	NavbarSettings.settings
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { copyToClipboard } from '@/js/utils/copy.js';
import { DateTime } from 'luxon';

import SimplePill from '@/components/base/SimplePill/SimplePill.vue';
import NavbarItem from './NavbarItem.vue';
import NavbarSettings from './NavbarSettings.vue';
import NavbarDropdown from './NavbarDropdown.vue';

export default {
	name: 'TheNavbar',
	components: {
		SimplePill,
		NavbarItem,
		NavbarSettings,
		NavbarDropdown
	},
	data() {
		return {
			showSettings: false,
			showMenu: false,
			conflictingContacts: [],
			fetchIssuesInterval: null
		};
	},
	computed: {
		menuIcon() {
			return this.showMenu ? 'cross' : 'burger';
		},
		showMigrationInfo() {
			return this.isProductionEnvironment || this.isStagingEnvironment;
		},
		migrationInfoText() {
			const systemList = this.unmigratedSystems.map(system => ` ${system.name}`);
			return `Systems NOT migrated: ${systemList}`;
		},
		navItems() {
			return [
				{
					title: 'Issues',
					name: 'issues',
					url: '/issues',
					activeUrls: [/\/issues\/[^/]+/]
				},
				{
					title: 'Organizations',
					name: 'organizations',
					url: '/organizations',
					activeUrls: [/\/organizations\/[^/]+/, /\/tenants\/[^/]+/, /\/mission_profiles\/[^/]+/]
				},
				{
					title: 'Contacts',
					name: 'contacts',
					url: '/contacts',
					activeUrls: [/\/contacts\/[^/]+/]
				},
				{
					title: 'Assets',
					name: 'assets',
					icon: 'file',
					url: '/assets',
					activeUrls: [
						/\/systems/,
						/\/systems\/[^/]+/,
						/\/spacecrafts/,
						/\/spacecrafts\/[^/]+/,
						/\/stations/,
						/\/stations\/[^/]+/
					]
				},
				{
					title: 'Styleguide',
					name: 'styleguide',
					url: '/styleguide',
					disabled: true
				}
			];
		},
		moreNavItems() {
			return [
				{
					title: 'Allotments',
					name: 'allotments',
					url: '/allotments',
					activeUrls: [/\/allotments\/[^/]+/]
				},
				...this.enableOrbitGuard
					? [
						{
							title: 'Orbit Guard',
							name: 'orbit_guard',
							url: '/orbit_guard',
							activeUrls: [/\/orbit_guard\/[^/]+/]
						}
					]
					: [],
				{
					title: 'Insight',
					name: 'insight',
					url: '/insight/availability',
					activeUrls: [/\/insight\/[^/]+/]
				},
				...this.enableVisualizer
					? [
						{
							title: 'Visualizer',
							name: 'visualizer',
							url: '/visualizer',
							activeUrls: [/\/visualizer\/[^/]+/]
						}
					]
					: [],
				{
					title: 'Schemas',
					name: 'schemas',
					url: '/schemas',
					activeUrls: [/\/schemas\/[^/]+/]
				},
				{
					title: 'Documentation',
					name: 'documentation',
					url: '/documentation',
					activeUrls: [/\/documentation\/[^/]+/]
				},
				{
					title: 'Signature Profiles',
					name: 'signature_profiles',
					url: '/signature_profiles',
					activeUrls: [/\/signature_profiles\/[^/]+/]
				},
				{
					title: 'KSAT IDs',
					name: 'ksat_ids',
					url: '/ksat_ids',
					activeUrls: [/\/ksat_ids\/[^/]+/]
				},
			];
		},
		allNavItems() {
			return this.navItems.concat(this.moreNavItems);
		},
		displayedNavItems() {
			return this.showMenu ? this.allNavItems : this.navItems;
		},
		moreActiveUrls() {
			let urls = [];
			this.moreNavItems.map(item => urls.push(item.url) && item.activeUrls.map(activeUrl => urls.push(activeUrl)));
			return urls;
		},
		showIssueNotification() {
			const activeOverlaps = this.overlappingConditions.filter(item => DateTime.fromISO(item.contactEnd, { zone: 'utc' }) > DateTime.utc().minus({ minutes: 10 }));
			return !!(this.conflictingContacts.length || activeOverlaps.length);
		},
		...mapGetters({
			now: 'time/now',
			environment: 'environment/environment',
			isProductionEnvironment: 'environment/isProductionEnvironment',
			isStagingEnvironment: 'environment/isStagingEnvironment',
			enableOrbitGuard: 'environment/enableOrbitGuard',
			enableVisualizer: 'environment/enableVisualizer',
			unmigratedSystems: 'migratedAssets/unmigratedSystems',
			overlappingConditions: 'problematics/all'
		})
	},
	mounted() {
		this.fetchIssues();
		this.fetchIssuesInterval = setInterval(this.fetchIssues, 60000);
	},
	unmounted() {
		clearInterval(this.fetchIssuesInterval);
	},
	methods: {
		async fetchIssues() {
			await this.fetchProblematics({});
			// Commenting out this due to possible cause of slowing down KOGS, should be revisited in the future.
			// this.conflictingContacts = await this.fetchContacts({ startTime: DateTime.utc(), endTime: DateTime.utc().plus({ days: 5 }), states: ['CONFLICTING'] });
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
		},
		copyISO() {
			copyToClipboard(DateTime.utc().toISO());
			this.$notify.event('Copied ISO date!', 'success');
		},
		...mapActions({
			fetchContacts: 'contacts/fetchRaw',
			fetchProblematics: 'problematics/fetch'
		})
	}
};
</script>

<style lang="scss" scoped>
.navbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	min-height: var(--navbar-height);
	flex-shrink: 0;
	padding: rem-calc(0 20);
	background: var(--navbar-background);
	z-index: 5;
	user-select: none;

	.development &,
	.dev & {
		background: hsl(var(--dark-purple110));
		box-shadow: inset 0 4px 0 hsl(var(--dev-color));
		border-left: 4px solid hsl(var(--dev-color));
		border-right: 4px solid hsl(var(--dev-color));
	}

	.test & {
		background: hsl(var(--dark-purple110));
		box-shadow: inset 0 4px 0 hsl(var(--test-color));
		border-left: 4px solid hsl(var(--test-color));
		border-right: 4px solid hsl(var(--test-color));
	}

	.staging &,
	.preprod & {
		background: hsl(var(--dark-purple110));
		box-shadow: inset 0 4px 0 hsl(var(--staging-color));
		border-left: 4px solid hsl(var(--staging-color));
		border-right: 4px solid hsl(var(--staging-color));
	}

	@media (max-width: $mobile-breakpoint) {
		--navbar-height: #{rem-calc(70)};
	}
}

.logo {
	display: flex;
	align-items: center;
	position: relative;

	>a {
		display: flex;
		align-items: center;
		position: relative;
		color: var(--body-color);
		text-decoration: none;
		line-height: 1;
	}

	img {
		width: rem-calc(47);
		margin-right: rem-calc(8);
	}

	.text {
		font-size: rem-calc(18);
		opacity: 0.5;
		letter-spacing: 2px;

		.development &,
		.dev & {
			opacity: 1;
			color: hsl(var(--dev-color));
		}

		.test & {
			opacity: 1;
			color: hsl(var(--test-color));
		}

		.staging &,
		.preprod & {
			opacity: 1;
			color: hsl(var(--staging-color));
		}
	}

	@media (max-width: $mobile-breakpoint) {
		z-index: 2;
		display: none;

		img {
			display: none;
		}

		.text {
			opacity: 1;
			font-weight: var(--font-boldest);
		}
	}
}

#toggle-nav {
	appearance: none;
	outline: none;
	align-self: center;
	display: flex;
	align-items: center;
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	background: transparent;
	color: var(--body-color);
	padding: rem-calc(8 10);
	font-weight: var(--font-bolder);
	z-index: 2;

	&:hover,
	&:active,
	&:focus {
		background: hsl(var(--white) / 10%);
	}

	.icon {
		margin-left: rem-calc(5);
	}

	@media (min-width: $mobile-breakpoint) {
		display: none;
	}
}

nav {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;

	#{v-deep('.more')},
	a {
		display: flex;
		align-items: center;
		color: var(--body-color);
		font-size: rem-calc(15);
		opacity: 0.6;
		text-decoration: none;
		padding: rem-calc(10 0);
		margin: rem-calc(0 15);
		text-shadow: var(--body-text-shadow);

		&.more {
			min-height: var(--navbar-height);
		}

		&.dropdown {
			padding: rem-calc(15 0);

			&:not(:last-child) {
				border-bottom: 1px solid hsl(var(--white20));
			}
		}

		&.issue-indicator {
			position: relative;

			&:before {
				top: 2.2rem;
				right: rem-calc(-5);
				width: rem-calc(6);
				height: rem-calc(6);
				position: absolute;
				display: block;
				border-radius: 50%;
				background: hsl(var(--orange));
				content: '';
			}
		}

		&:hover {
			box-shadow: inset 0 -2px 0 hsl(var(--white));
			color: inherit;
			opacity: 1;

			&.dropdown {
				box-shadow: none;
				border-bottom: 1px solid (hsl(var(--white)));
			}
		}

		&.is-active {
			opacity: 1;
			box-shadow: inset 0 -2px 0 hsl(var(--white));

			&.dropdown {
				box-shadow: none;
				border-bottom: 1px solid (hsl(var(--white)));
			}
		}
	}

	@media (max-width: $mobile-breakpoint) {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: var(--body-background);
		z-index: 1;
		justify-content: flex-start;
		padding-top: var(--navbar-height);

		#{v-deep('.more')},
		a {
			font-size: rem-calc(24);

			&.is-active {
				font-weight: var(--font-boldest);
				opacity: 1;
				box-shadow: none;
				color: hsl(var(--orange));
			}

			&.issue-indicator {
				&:before {
					top: 0.7rem;
					right: auto;
					left: 5rem;
				}
			}
		}

		.show-menu & {
			display: flex;
			flex-flow: column;
		}
	}
}

.pill {
	padding: rem-calc(5);
	margin: rem-calc(5);
}

.settings {
	#{v-deep('.popper')} {
		width: 350px;
	}
}

.migrated-info {
	display: flex;
	align-items: center;
	position: relative;
	margin: rem-calc(0 5 0 5);

	#{v-deep('.popper')} {
		width: rem-calc(230);
	}
}

#utc-time {
	display: flex;
	align-self: center;
	justify-content: center;
	padding: rem-calc(0 5);
	height: $navbar-labels-size;
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	font-weight: var(--font-bolder);
	font-size: rem-calc(12);
	margin-left: rem-calc(20);
	text-transform: uppercase;
	white-space: nowrap;
	cursor: pointer;
	z-index: 2;

	&:hover {
		border-color: hsl(var(--white40));

		.copy .icon {
			opacity: 1;
		}
	}

	>div {
		display: flex;
		align-items: center;
		margin: rem-calc(0 5);
	}
	.date {
		width: rem-calc(80);
	}

	.time {
		width: rem-calc(100);
	}

	.icon {
		opacity: 0.4;
		margin-right: rem-calc(5);
	}

	.copy {
		margin: 0;
	}

	@media (max-width: $mobile-breakpoint) {
		margin: 0;
	}
}
</style>
